.form {
  display: flex;

  flex-direction: column;
}

.fields {
  display: flex;

  margin-bottom: 24px;

  gap: 8px;
}

.score,
.scoreAmount {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #20233a;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scoreAmount {
  display: flex;
  gap: 15px;
}
