.fields {
  display: flex;

  margin-bottom: 24px;

  gap: 8px;
}

.buttons {
  display: flex;

  gap: 8px;
}

.scoreAmount {
  display: flex;
  gap: 15px;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #20233a;
}
