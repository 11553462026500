.table {
  display: flex;

  width: 100%;

  flex-direction: column;
}

.table__headers {
  display: flex;

  height: 60px;

  border-top: 2px solid #e9ecf0;
  border-bottom: 2px solid #e9ecf0;
}

.table__header {
  display: flex;

  padding: 18px 16px;

  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;
}

.table__header_replenished {
  width: 60%;
  border-right: 2px solid #e9ecf0;
}
.table__header_totalCoins {
  width: 20%;
  border-right: 2px solid #e9ecf0;
}
.table__header_date {
  width: 20%;
}

.table__rows {
  display: flex;

  margin-bottom: 26px;

  flex-direction: column;

  background-color: #ffffff;
  border-bottom: 2px solid #e9ecf0;
}

.table__row {
  display: flex;

  height: 60px;

  transition: all 0.3s ease;
}

.table__row:hover {
  background-color: #eef0ff;
}

.table__row_even {
  background-color: #fafafa;
}

.table__cell {
  display: flex;

  height: 60px;

  padding: 20px 15px;

  align-items: center;
}

.table__cell_replenished {
  width: 60%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_replenished span {
  font-size: 16px;
  line-height: 19px;
  color: #5770f3;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_totalCoins {
  width: 20%;
  border-right: 2px solid #e9ecf0;
}

.table__cell_totalCoins span {
  font-size: 16px;
  line-height: 19px;
  color: #20233a;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__cell_date {
  width: 20%;
}

.table__cell_date span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71798f;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
